import React, { useContext } from "react"
import { LanguageContext } from "../../context"
import Icon from "../icon"
import PropTypes from "prop-types"

const ServicesItem = (props) => {

   const { iconTitle, title, icon } = props
   const { t } = useContext(LanguageContext)

   return (
     <div className="service-line-item p-b-10 m-b-10">
       <div className="line-item-label label">
          {icon &&
            <span className="icon-wrapper">
            <Icon
              icon={icon}
              className="icon m-r-12"
              size={24}
              color="green"
              title={iconTitle}
           />
           </span>
         }
         <span className="title-wrapper">
          {title &&
            t(title)
          }
        </span>
       </div>
     </div>
   )

 }

 ServicesItem.propTypes = {
   title: PropTypes.string.isRequired,
   value: PropTypes.string,
   icon: PropTypes.string,
   iconTitle: PropTypes.string
 }

 ServicesItem.defaultProps = {
   title: ``,
   value: ``,
   icon: ``,
   iconTitle: ``
 }
 export default ServicesItem
